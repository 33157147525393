<template>
  <div class="viewFeedMechanism">
    <div class="header">
      <div>
        <span class="supplierName">{{ siteName }}</span>
        项目
      </div>
      <el-button type="primary" @click="exportFeed">导出</el-button>
    </div>
    <el-table
      class="ly_table"
      :data="viewFeedMechanismTable"
      :header-cell-style="{
        background: '#F5F6F7',
        color: '#363E4D',
        'text-align': 'center'
      }"
      :cell-style="{ 'text-align': 'center' }"
      @selection-change="handleSelectionChange"
    >
      <template slot="empty">
        <img src="@/assets/img/table.png" alt="没有数据" srcset />
      </template>
      <el-table-column type="selection" width="70"></el-table-column>
      <el-table-column label="序号" align="center" width="80">
        <template v-slot="scope">
          <span>
            {{
              (queryInfo.pageNum - 1) * queryInfo.pageSize + (scope.$index + 1)
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="imgLink" label="图片">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            trigger="hover"
            v-if="scope.row.imgLink"
          >
            <img
              :src="scope.row.imgLink"
              style="max-height: 300px; max-width: 300px"
            />
            <img
              slot="reference"
              :src="scope.row.imgLink"
              style="max-height: 100px; max-width: 100px; cursor: pointer"
              @click="editTalent(scope.row)"
            />
          </el-popover>
          <div @click="editTalent(scope.row)" style="cursor: pointer" v-else>
            <el-image
              :src="require('../../../assets/img/pro.png')"
              class="headerImg"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题">
        <template slot-scope="scope">
          <div @click="editTalent(scope.row)" style="cursor: pointer">
            <span>{{ scope.row.title }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="articleReleaseTime"
        label="发布时间"
        width="200"
      ></el-table-column>
      <el-table-column prop="text" label="简介" width="350">
        <template slot-scope="scope">
          <el-popover
            placement="right"
            width="300"
            trigger="hover"
            :content="scope.row.text"
          >
            <div slot="reference">
              <div v-if="scope.row.text !== null && scope.row.text !== ''">
                {{ scope.row.text.substring(0, 100) }}
                <span v-if="scope.row.text.length > 100">...</span>
              </div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="crawlWebsite" label="爬取网站"></el-table-column>
      <el-table-column
        prop="informationType"
        label="资讯类型"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="creator"
        label="创建人"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        width="120"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <button-table
            @buttonClick="viewItem(scope.row)"
            content="查看"
            icon="jr-iconjr-icon-eye"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="queryInfo.pageNum"
      :page-sizes="[3, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { pagination } from "@/mixins/pagination";
import { listRecruitmentResourcePageOutputNum } from "@/api/news.js";
import ButtonTable from "@/components/ButtonTable.vue";
import { exportData } from "@/utils/tool";
export default {
  name: "ViewFeedMechanism",

  components: {
    ButtonTable
  },

  mixins: [pagination],

  data() {
    return {
      name: "",
      viewFeedMechanismTable: [],
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
        columnNames: ["update_time"],
        siteName: "",
        id: "",
        order: 1
      },
      ids: []
    };
  },
  computed: {
    sponsorId() {
      return Number(this.$route.query.id);
    },
    siteName() {
      return this.$route.query.newsData;
    },
    newsDataId() {
      return this.$route.query.newsDataId;
    }
  },
  created() {
    this.search();
  },

  methods: {
    async search() {
      // Object.assign(this.queryInfo, { siteName: this.siteName });
      this.queryInfo.siteName = this.siteName;
      this.queryInfo.id = this.newsDataId;
      const res = await listRecruitmentResourcePageOutputNum(this.queryInfo);

      if (res.code === 200) {
        this.viewFeedMechanismTable = res.data.list;
      }

      this.total = res.data.total;
    },
    handleSelectionChange(val) {
      this.ids = val.map(item => {
        return item.id;
      });
    },
    viewItem(row) {
      // const data = row.id.toString();
      this.$router.push({
        path: "/news/newsInformation/newsInfo",
        query: {
          newsData: row.id,
          personType: 1
        }
      });
    },
    exportFeed() {
      if (this.ids.length > 0) {
        const params = {
          idList: this.ids
        };

        exportData(
          params,
          "/newsSource/exportExcel",
          "application/msexcel",
          this.search,
          "资讯数据.xls"
        );
      } else {
        const params = {
          siteNameToday: this.siteName,
          siteName: this.siteName
        };
        this.$confirm("是否导出所有数据？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            exportData(
              params,
              "/newsSource/exportExcel",
              "application/msexcel",
              this.search,
              "资讯数据.xls"
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "导出失败"
            });
          });
      }
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    }
  }
};
</script>

<style lang="less" scoped>
.viewFeedMechanism {
  background: #fff;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    .supplierName {
      color: #1e9fff;
      font-size: 18px;
      margin-right: 5px;
    }
  }
}
</style>
